































































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import { IllnessLookupModel, IllnessModel, OsicsLookupModel } from '@/core/webapi';
import { OsicsService, ReportLookupService, AutoSaveService, ApiService } from '@/core/services';
import { QcFormElementWithAlertCpt } from '../components';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputTextareaCpt,
  InputDateCpt,
  InputSelectCpt,
  InputSwitchCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { QcStatus, StudyTypes } from '@/core/constants';

@Component({
  components: {
    QcFormElementWithAlertCpt,
    InputFieldCpt,
    InputTextareaCpt,
    InputDateCpt,
    InputSelectCpt,
    InputSwitchCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class IllnessFormView extends BaseFormComponent {
  lookups = {} as IllnessLookupModel;
  model = new IllnessModel({ modelState: {} } as any);
  comparisonModel: IllnessModel;
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };
  autoSaveTimeoutId?: number;
  showDialog = false;
  loading = true;

  get playerId() {
    return this.getUrlParamAsNumber('playerId');
  }

  get illnessId() {
    return this.getUrlParamAsNumber('illnessId');
  }

  get autoSaveId() {
    return this.getUrlParamAsNumber('autoSaveId');
  }

  get osicsCode() {
    return OsicsService.getOsicsCode(
      this.lookups.osics!,
      this.model.osicsRegionId,
      this.model.osicsParentId,
      this.model.osicsSpecificId,
      this.model.osicsDetailId,
    );
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.organisationId, StudyTypes.Elite);
  }

  get selectedStudy() {
    return this.studies ? this.studies.find(p => p.id === this.model.studyId) : null;
  }

  get isSevensStudy() {
    return this.studies?.find(p => p.id === this.model.studyId)?.gameFormatId === 2;
  }

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookupData();
      this.initializeAutoSave();
    } finally {
      this.loading = false;
    }
  }

  destroyed() {
    this.clearAutoSaveTimeout();
  }

  onOrganisationChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.studyId;
  }

  getOsicsParentPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsParentPrefix(osics, this.osicsCode);
  }

  getOsicsSpecificPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsSpecificPrefix(osics, this.osicsCode);
  }

  getOsicsDetailPrefix(osics: OsicsLookupModel) {
    return OsicsService.getOsicsDetailPrefix(osics, this.osicsCode);
  }

  onOsicsRegionChanged() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.osicsParentId;
    this.onOsicsParentChanged();
  }

  onOsicsParentChanged() {
    delete this.model.osicsSpecificId;
    this.onOsicsSpecificChanged();
  }

  onOsicsSpecificChanged() {
    delete this.model.osicsDetailId;
  }

  getOsicsChildren(regionId: number, parentId?: number, specificId?: number) {
    return OsicsService.getOsicsChildren(this.lookups.osics!, regionId, parentId, specificId);
  }

  async onSubmitForm() {
    if (!this.illnessId) {
      this.model.qcStatusId = QcStatus.New;
      await this.completeFormSubmission();
    } else {
      this.showDialog = true;
    }
  }

  onDialogClose() {
    this.showDialog = false;
  }

  async onDialogButton(isReadyForQc: boolean) {
    this.model.qcStatusId = isReadyForQc ? QcStatus.AwaitingAnalyst : QcStatus.AwaitingReporter;
    this.showDialog = false;
    await this.completeFormSubmission();
  }

  async completeFormSubmission() {
    this.clearAutoSaveTimeout();

    try {
      this.illnessId
        ? await ApiService.illnesses().updateIllness(this.model).handleValidationErrors(this.model)
        : await ApiService.illnesses().createIllness(this.model).handleValidationErrors(this.model);
    } catch (e) {
      // Typically we can allow the failed request (422) to stop the execution flow of onSubmitForm(),
      // but it breaks the autoSave feature timeout, so we need to handle it explicitly
      return;
    }

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Illness report saved') as string,
      type: 'success',
    });

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
    }

    this.illnessId ? this.$router.push('/illnesses') : this.$router.push('/players');
  }

  async discard() {
    if (!confirm(this.$t('Are you sure you want to discard local changes for this report?') as string)) {
      return;
    }

    this.clearAutoSaveTimeout();
    if (!this.model.autoSaveId) {
      return;
    }

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
      this.clearAutoSaveTimeout();
    }

    this.$router.push('/players');
  }

  private async initializeModel() {
    if (this.illnessId) {
      const model =
        (await AutoSaveService.findIllnessAutoSave(this.illnessId)) ||
        (await ApiService.illnesses().getIllness(this.illnessId).getDataAndDefaultValidationProps());

      _.extend(this.model, model);
    } else if (this.autoSaveId) {
      const model = await AutoSaveService.getIllnessAutoSave(this.autoSaveId);
      _.extend(this.model, model);
    } else {
      _.extend(this.model, { playerId: this.playerId });
    }

    this.updateComparisonModel();
  }

  private async initializeLookupData() {
    this.lookups = (await ApiService.illnesses().getIllnessFormLookupData(this.model.playerId!)).data;

    if (!this.illnessId) {
      this.model.osicsRegionId = this.lookups.osics![0].id!;
    }

    ReportLookupService.resolveOrgStudyFilterIds(this.lookups, this.model, false, StudyTypes.Elite);
    this.updateComparisonModel();
  }

  private updateComparisonModel() {
    this.comparisonModel = JSON.parse(JSON.stringify(this.model)); // Make a copy
  }

  private initializeAutoSave() {
    if (!this.isReporter) {
      // Auto-saving is only for reporters really
      return;
    }

    this.clearAutoSaveTimeout();

    this.autoSaveTimeoutId = setTimeout(async () => {
      if (!this.$auth.isAuthenticated()) {
        return;
      }

      if (this.autoSaveTimeoutId) {
        const autoSave = await AutoSaveService.autoSaveIllness(this.model, this.comparisonModel);
        if (!!autoSave) {
          this.model.autoSaveId = autoSave.id;
          this.updateComparisonModel();
        }
        this.initializeAutoSave();
      }
    }, 5000);
  }

  private clearAutoSaveTimeout() {
    if (this.autoSaveTimeoutId) {
      clearTimeout(this.autoSaveTimeoutId);
      this.autoSaveTimeoutId = undefined;
    }
  }
}
