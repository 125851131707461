




































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { IllnessCollectionLookupModel, IllnessCollectionModel, IllnessGridFilter } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
  StudyOrgFilterCpt,
} from '@/core/components/grid';
import { ReturnStatusTagCpt, QcStatusTagCpt } from '../components';
import { GameFormats, StudyTypes } from '@/core/constants';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    StudyOrgFilterCpt,
    ReturnStatusTagCpt,
    QcStatusTagCpt,
  },
})
export default class IllnessGridView extends AppVue {
  grid = {} as GridModel<IllnessCollectionModel>;
  lookups = {} as IllnessCollectionLookupModel;
  studyTypeId = StudyTypes.Elite;

  get showTournamentColumn() {
    if (!this.grid.collection?.items) {
      return false;
    }

    if (!this.grid.filter.studyId) {
      return !!this.isAnalyst;
    }

    const selectedStudy = ReportLookupService.getSelectedStudy(
      this.lookups,
      this.grid.filter as IllnessGridFilter,
      this.isReporter,
    );

    if (!selectedStudy) {
      return !!this.isAnalyst;
    }

    return selectedStudy.gameFormatId === GameFormats.Sevens;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.illnesses().filterIllnesses(filter),
      routeName: 'illnesses',
      defaultFilterOverrides: new GridFilter(await this.initializeFilters()),
    });
  }

  async initializeFilters() {
    this.lookups = (await ApiService.illnesses().getIllnessCollectionLookupData()).data;

    let initialFilter = {
      search: '',
      organisationId: null,
      studyId: null,
      returnStatusId: null,
      qcStatusId: null,
    };

    return ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      initialFilter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  async deleteItem(id: number) {
    if (!confirm(this.$t('Are you sure you want to delete this item?') as string)) {
      return;
    }

    await ApiService.illnesses().deleteIllness(id);
    this.grid.update();
  }
}
